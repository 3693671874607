<template>
  <v-card-subtitle class="pa-lg-2" v-if="orderType">
    <hr class="mt-0 mb-3" />
    <p style="text-align: center">
      TOTAL DE LA FACTURACIÓN DEL TRIMESTRE ANTERIOR:
      <span v-if="isRuleEmpty" style="font-weight: 800"
        >${{ formatPrice(parseFloat(this.rules.TOTALM).toFixed(2)) }}</span
      >
      <span v-else><b>$ 0</b></span>
    </p>
    <v-progress-linear
      bg-color="#b00020"
      color="#b00020"
      v-model="percentage"
      height="25"
    >
      <strong style="color: #fff">{{ percentage }}%</strong>
    </v-progress-linear>
    <v-row>
        <v-col cols="6" sm="12" md="6" class="mt-3">
        <span style="font-size: 12px"
          >30% DEL MONTO MÁXIMO DE COMPRA DE CONDUCTOR:
          <span v-if="isRuleEmpty" style="font-weight: 800; font-size: 14px">
            ${{ formatPrice(parseFloat(this.rules.PORCENT).toFixed(2)) }}
          </span>
          <span v-else><b>$ 0</b></span>
        </span>
      </v-col>
      <v-col cols="6" sm="12" md="6" class="mt-3">
        <span style="font-size: 12px"
          >MONTO RESTANTE DE COMPRA DE CONDUCTOR:
          <span v-if="isRuleEmpty" style="font-weight: 800; font-size: 14px">
            ${{ formatPrice(parseFloat(this.rules.SALDO).toFixed(2)) }}</span
          >
          <span v-else><b>$ 0</b></span>
        </span>
      </v-col>
    </v-row>
  </v-card-subtitle>
</template>
<script>
export default {
  name: "MaterialRulesInvoice",
  props: {
    order: {
      type: String,
      required: true,
    },
    rules: {
      type: Object,
      required: false,
    },
  },
  computed: {
    orderType: function () {
      switch (this.order) {
        case "PSDT":
          return true;
        case "PSNW":
          return true;
        default:
          return false;
      }
    },

    isRuleEmpty: function () {
      if (this.rules.length != 0) {
        return true;
      }
      return false;
    },
    percentage: function () {
      if (this.rules.length != 0) {
        return Math.abs(
          (
            ((parseInt(this.rules.SALDO) - parseInt(this.rules.PORCENT)) /
              parseInt(this.rules.PORCENT)) *
            100
          ).toFixed(0)
        );
      }
      return 0;
    },
  },
  methods: {
    formatPrice(value) {
      if (value) {
        if (value < 0) {
          var cred = value.split("-");
          return "-" + cred[1].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      return "0,00";
    },
  },
};
</script>
