<template>
    <div id="contenedor" ref="ShoppingCart">
    <v-container fluid class="grey lighten-5 shopping-cart-card" ref="printResultSellerView">
      <div class="d-flex align-items-center">
            <v-card class="mx-auto rounded-lg elevation-4 ma-3 w-100" outlined :style="'border: 4px solid black; background-color:transparent;' ">
                <v-list-item-content>
                    <div class="text-general-module mb-0">
                      <span v-if=" doctype == 'PSIU'" class="icoiusa-checkbox-marked"></span>
                      <span>&nbsp;PEDIDO BACKORDER {{$route.params.type}}</span>
                      <span v-if=" doctype == 'PSIU'"></span>
                    </div>                    
                </v-list-item-content>               
            </v-card>            
    </div>  
    <div class="grid-container mt-25">
        <div class="customer mt-5">
            <v-row >
                <v-col v-if="isDisabledTaxData" cols="12" sm="12" md="4" class="mt-3 mb-0 text-left">
                    <label class="font-weight-bold font-size-9 mt-0 mb-1">Método de pago:</label>
                    <v-autocomplete solo dense hide-details="auto" v-model="select_metodo" :items="item_metodo_pago"
                        :item-text="getItemTextMetodo" item-value="id" elevation="2">
                    </v-autocomplete>
                </v-col>
                <v-col v-if="isDisabledTaxData" cols="12" sm="12" md="4" class="mt-3  mb-0 text-left">
                    <label class="font-weight-bold font-size-9 mt-0 mb-1">Vía de Pago :</label>
                    <v-autocomplete solo dense v-model="select_v_pay" :items="item_v_pay"
                        :item-text="getItemTextVPay" item-value="id" elevation="2">
                    </v-autocomplete>
                </v-col>
                <v-col v-if="isDisabledTaxData" cols="12" sm="12" md="4" class="mt-3  mb-0 text-left">
                    <label class="font-weight-bold font-size-9 mt-0 mb-1">Uso de CFDI :</label>
                    <v-autocomplete solo dense v-model="select_use_cfdi" :items="item_use_cfdi"
                        :item-text="getItemTextUseCfdi" item-value="id" elevation="2">
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="6" class="mt-0  mb-0 text-left">
                    <label class="font-weight-bold font-size-9 mt-0 mb-2">Documento Relacionado:</label>
                    <v-textarea v-model="documents" bg-color="success" filled  solo rows="1"></v-textarea>
                </v-col>
                <v-col cols="12" sm="12" md="6" class="mb-0">
                    <label class="font-weight-bold font-size-9 mt-0 mb-2">CFDI Relacionado:</label>
                    <v-autocomplete solo dense v-model="v_cfdi" :items="items_cfdi"></v-autocomplete>
                </v-col>
            </v-row>
      </div>
      <div class="destination mt-5">
        <destination-selector  
          v-model="destiny"
          :destinations="destinations" 
          :loading="destinations.length == 0"
        />
      </div>
      <div class="type-1 mt-5">
        <shopping-card :showTitle="false" class="w-full">
            <div class="d-flex align-items-baseline mt-1">
            <span class="w-40 text-left font-size-9"
              >Ingrese Orden de Compra:</span
            >
            <v-text-field
              dense
              solo
              class="text-ped pt-0 mt-0 font-size-8 mt-7 mb-1"
              v-model="order"
              required
            ></v-text-field>
          </div>
        </shopping-card>
      </div>
      <div class="search mt-5">
        <shopping-card :showTitle="false" class="w-full">
        <span class="w-40 text-left font-weight-bold font-size-9 mt-2"
              >Búsqueda</span>
              <v-form ref="searchMaterialForm">
            <div class="d-flex align-items-baseline">
              <span class="w-40 text-left font-weight-bold font-size-9">Código de material-SKU/Descripción:</span>
              <v-text-field dense solo class="text-ped pt-0 mt-0 w-40" v-model="searchForm.sku" :disabled="showNameField"
                required type="text" :rules="[(v) => !!v || 'Este campo es requerido']"
                v-on:keyup.enter="onEnterCode()">
                <template v-slot:append>
                  <v-btn v-if="searchForm.sku" icon @click="clearSku">
                    <v-icon>mdi-close-circle</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
              <div id="resultados"></div>
            </div>
  
            <div class="d-flex align-items-baseline">
              <span class="w-40 text-left font-weight-bold font-size-9">Unidades:</span>
              <v-text-field dense solo class="text-ped pt-0 mt-0 w-40" v-model="searchForm.quantity" required
                :rules="[(v) => !!v || 'La cantidad es requerida', (v) => !!/^[0-9]+$/.test(v) || 'Sólo se admiten números']"
                ref="units"
                v-on:keyup.enter="addItemToCart"></v-text-field>
              <div class="w-20"></div>
            </div>
          </v-form>
          <div v-if="showNameField">
            <div class="d-flex d-inline-block">
              <div style="width: 60px" class="mr-1">
              <img class="w-full" :src="searchForm.url" alt="" />
            </div> 
            <v-text-field v-model="searchForm.name" label="Nombre" solo readonly></v-text-field>
            </div> 
          </div>
          <div class="mt-2">
            <v-btn class="button-green px-16 text-white font-weight-black py-5" rounded-md @click="addItemToCart">
              <span>Agregar a carrito</span>
              <v-icon>mdi-cart</v-icon>
            </v-btn>
          </div>
      </shopping-card>
    </div>
     <div class="data-reng justify-center">
      <history-shopping-item :count-item="countItem" :data-history-shopping="dataHistoryShopping"/>
    </div>
    <div class="custumer-cred mt-5">
      <v-card-subtitle class="pa-lg-2">
          <p style="text-align: center">
            LIMITE DE CRÉDITO:
            <span style="font-weight: 800">${{ formatPrice(customerInfo.KLIMK) }}</span>
          </p>
          <v-progress-linear v-model="porcentaje" height="25">
            <strong style="color: #fff">{{ porcentaje }}%</strong>
          </v-progress-linear>
          <v-row>
            <v-col cols="6" sm="12" md="6" class="mt-3">
              <span style="font-size: 12px"
                >SALDO AL CORTE:
                <span style="font-weight: 800; font-size: 14px"
                  >${{ formatPrice(customerInfo.SKFOR) }}</span
                ></span
              >
            </v-col>
            <v-col cols="6" sm="12" md="6" class="mt-3">
              <span style="font-size: 12px"
                >CREDITO DISPONIBLE:
                <span style="font-weight: 800; font-size: 14px"
                >${{ formatPrice(aviable.toFixed(2)) }} 
              </span></span
              >
              <br>
              <span style="font-size: 12px" class="mt-1">
                CREDITO DISPONIBLE PEDIDO ACTUAL: 
              <span style="font-weight: 800; font-size: 14px"
                >${{ formatPrice(totalCred.toFixed(2)) }} 
              </span>
            </span>
            </v-col>
          </v-row>
          <p></p>
        </v-card-subtitle>
    </div>
    <div class="order-plus">
     <!--<div class="d-flex mb-1"> limpar pedido borra los materiales -->  
      <v-row md="12">
        <v-col md="6">
        <v-btn small block 
       
        class="pt-0 mt-0  ml-4 text-black font-weight-bold font-size-10 shadow-b"
        @click="cleanOrder"
          >
          Limpiar Pedido 
          <span class="ml-2 icoiusa-plus-circle"></span>
        </v-btn>
        </v-col>
        <v-col md="6">
        <v-btn small  
        block
        class="pt-0 mt-0 ml-3 text-black font-weight-bold font-size-10 shadow-b"
        @click="goHistoryOrdes"
          >
          Backorders
          <span class="ml-2 icoiusa-check-circle"></span>
          <span class=" icoiusa-group-circle"></span>
        </v-btn>
        </v-col>
      </v-row>
      <!--</div>-->
    </div>
    </div>
    <v-row>
      <v-col cols="12" class="mt-5">
        <v-simple-table>
          <thead class="table-header-pr">
            <tr>
              <th class="table-header-pr text-center">Producto</th>
              <th v-if="!this.$hasAnyRole(['Customer','CustomerPR'])" class="table-header-pr text-center">Genética</th>
              <th class="table-header-pr text-center">
                Unidad de medida de venta
              </th>
              <th class="table-header-pr text-center">Empaque</th>
              <th class="table-header-pr text-center">U. Solicitadas</th>
              <th class="table-header-pr text-center">U. Confirmadas</th>
              <th v-if="!this.$hasAnyRole(['Customer','CustomerPR'])" class="table-header-pr text-center">Existencia</th>
              <th v-if="!this.$hasAnyRole(['Customer','CustomerPR'])" class="table-header-pr text-center">Existencia CDPT</th>
              <th class="table-header-pr text-center">Precio Unitario</th>
              <th class="table-header-pr text-center">Precio C/ descuento</th>
              <th class="table-header-pr text-center">Importe Total</th>
              <th class="table-header-pr"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in statecart" :key="`product-${i}`">
              <td class="h-auto d-flex flex-nowrap align-center">
                <!-- PRODUCT IMAGE THUMBNAIL  -->
                <div style="min-width: 70px; min-height: 100%;">
                  <v-progress-circular
                    v-if="!item.image_url"
                    indeterminate
                    color="primary"
                  />
                  <v-img
                    v-else
                    :src="item.image_url"
                    max-height="100%"
                    max-width="70px"
                    class="cursor-pointer"
                    contain
                    @click="openProductImageGallery(item, i)"
                  />
                </div>

                <div class="d-flex flex-column">
                  <span class="text-left font-size-8"
                    >
                    <special-discount-icon
                     :discount="item"
                     />
                  </span>
                  <p
                    class="text-left font-size-8 mb-1" 
                    style="max-width: 150px; word-wrap: break-word"
                  >
                    {{ item.nombre_material }}
                  </p>
                  <div class="d-flex align-center">
                    <v-btn 
                      icon 
                      @click="
                        product = item;
                        isProductTechnicalShetsDialogOpen = true
                    ">
                      <v-icon color="red red-darken-4">
                        mdi-folder-outline
                      </v-icon>
                    </v-btn>
                    <span class="text-left font-size-8">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            :class="item.validacion"
                            v-bind="attrs"
                            v-on="on"
                            >mdi-alert-circle
                          </v-icon>
                        </template>
                        <span>{{ item.stock_label }}</span>
                      </v-tooltip>
                    </span>
                  </div>
                </div>              
              </td>
              <td v-if="!$hasAnyRole(['Customer','CustomerPR'])" class="text-center">
                <special-genetics-icons :genetics="item.material_type"/>
              </td>
              <td>
                <span class="font-size-8">{{ item.unidad_medida }}</span>
              </td>
              <td>
                <span class="font-size-8">{{ item.empaque }}</span>
              </td>
              <td class="text-center"> 
                <v-text-field v-if="item.backorder_id == null" class="font-size-8 mb-0"  style="width: 90px; font-size: 10px;" dense hide-details single-line v-model="item.u_pedidas" @keydown.enter.prevent="editQuantity(item, item.u_pedidas)">
                </v-text-field>
                <a v-else  class="text-black">
                  <span class="font-size-8"
                    >{{ formatInt(item.u_confirm) }}
                    </span
                  >
                 </a>

                <p class="font-size-8">{{
                      item.recordatorios != 0
                        ? `BACKORDER ${item.recordatorios}`
                        : ""
                    }}</p>
              </td>
              <td>
                <span class="font-size-8">{{ item.u_confirm }}</span>
              </td>
              
              <td v-if="!$hasAnyRole(['Customer','CustomerPR'])">
                <span class="font-size-8">{{ format(item.existencia) }}</span>
              </td>
              <td v-if="!$hasAnyRole(['Customer','CustomerPR'])">
                <span class="font-size-8">{{ format(item.existencia_cdpt) }}</span>
              </td>
              <td>
                <span class="font-size-8"
                  >$ {{ format(item.precio_lista) }}</span
                >
              </td>
              <td>
                <span class="font-size-8"
                  >$ {{ format(item.precio_con_descuento) }}</span
                >
              </td>
              <td>
                <b class="font-size-8">$ {{ format(item.importe_producto) }}</b>
              </td>
              <td>
                <button class="icon-button" @click="deleteItem(item)">
                  <img src="/img/trash-can-outline.svg" alt="" />
                </button>
              </td>
            </tr>
            <tr v-if="statecart.length <= 0">
              <td colspan="12" class="text-center">
                No hay productos en el carrito
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
    <loading-bar v-model="dialogLoading"></loading-bar>
    <!--AQUI DEBAJO DEL CARRITO--->
    <div class="mt-8 footer-container">
      <div class="btn-total">
        <div
          class="w-full text-left py-1 new-card-title new-card-pink d-flex justify-space-around"
        >
          <span class="w-50 text-center">Subtotal del pedido:</span>
          <span class="text-center"
            >$ {{ formatPrice(statesubtotal.toFixed(2)) }}</span
          >
        </div>
        <div
          class="w-full text-left py-1 new-card-title new-card-pink d-flex justify-space-around"
        >
          <span class="w-50 text-center">I.V.A.</span>
          <span class="text-center"
            >$ {{ formatPrice(stateiva.toFixed(2)) }}</span
          >
        </div>
        <div
          class="w-full text-left py-1 new-card-title  d-flex justify-space-around"
          style="background-color: #3C6A93; color: #fff;"
        >
          <span class="w-50 text-center">Total con IVA MXN</span>
          <span class="text-center"
            >$ {{ formatPrice(statetotal.toFixed(2)) }}</span
          >
        </div>
        <v-btn
          :class="isButtonDisabled == true ? 'button-green-process w-full text-white font-weight-bold font-size-8 mt-8': 'button-green w-full text-white font-weight-bold font-size-8 mt-8'"
          @click="checkBackOrder"
          :disabled="isButtonDisabled"
          >
          <span class="text-white" style="font-size: 12px;" v-if="isButtonDisabled">Compra en Proceso <v-icon>mdi-checkbox-multiple-marked-circle</v-icon></span>
          <span v-else>CONFIRMAR PEDIDO<v-icon class="ml-3">mdi-checkbox-multiple-marked-circle</v-icon></span>
          <span ></span>
        </v-btn>
      </div>
      <div class="btn-save align-items-baseline">
        <v-row class="mt-0">
          <v-col md="6">
            <v-btn small block class="pt-0 mt-0 ml-1 text-black font-weight-bold font-size-10 shadow-b" @click="goTo()">
              Carátula cliente
              <span class="ml-2 icoiusa-chart-box-outline"></span>
            </v-btn>
          </v-col>
          <v-col md="6">
            <v-btn small block class="pt-0 mt-0 ml-3 text-black font-weight-bold font-size-10 shadow-b" @click="generateFiles">
              Generar Archivo CSV
              <span class="ml-2 icoiusa-file-download"></span>
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </div>
    <v-row>
      <legacy-footer></legacy-footer>
    </v-row>
    <v-dialog v-model="quantityDialog.show" persistent max-width="700">
      <v-card>
        <v-card-title>
          <span class="text-h5">Editar cantidad</span>
        </v-card-title>
        <v-card-text>
          <div class="d-flex align-items-baseline">
            <span class="w-30 text-left font-weight-bold font-size-9"
              >Producto:</span
            >
            <v-text-field
              dense
              solo
              class="text-ped pt-0 mt-0 w-40"
              required
              readonly
              v-model="quantityDialog.name"
            ></v-text-field>
          </div>
          <div class="d-flex align-items-baseline">
            <span class="w-30 text-left font-weight-bold font-size-9"
              >Nueva cantidad:</span
            >
            <v-text-field
              dense
              solo
              class="text-ped pt-0 mt-0 w-40"
              required
              type="number"
              min="1"
              v-model="quantityDialog.quantity"
            ></v-text-field>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="cancelQuantity">Cancelar</v-btn>
          <v-btn color="primary" @click="saveQuantity">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
     <!-- Dialog loading -->
    <v-dialog v-model="dialogBackOrder" persistent  width="550" min-width="550" scrollable>
      <v-card>
        <div class="w-full bg-black d-flex justify-content-between align-center px-4">
          <span class="text-white d-flex align-center font-weight-bold">Backorder</span>
          <v-btn icon small style="bg-gray" @click="dialogBackOrder = false">
            <v-icon class="text-white font-size-8">mdi-close</v-icon>
          </v-btn>
        </div>
        <div class="w-9 bg-white d-flex flex-wrap">
          <h4 class="w-full mt-4 font-weight-bold" style="color: grey;">CONFIRMAR BACKORDER</h4>
          <span class="solid_line"></span>
          <span class="text-title-bk font-weight-bold mt-3 mb-5">Seleccione los backorders confirmados</span>
          <!-- Verifica si la lista de items está vacía -->
          <result-item-backorder class="mr-5 ml-5" v-for="(item, i) in statecart" :key="i.codigo_material" :item="{            
            materialCode: item.codigo_material.slice(-6),
            name: item.nombre_material,
            sku: item.codigo_material.slice(-6),
            reminder: item.reminder_check,
            recordatorios: item.recordatorios,
            precio_lista: item.precio_lista}">
            <div style="width: 60px" class="mr-1">
              <img class="w-full" :src="item.image_url" alt="" />
            </div>
          </result-item-backorder>
        </div>
        <v-layout justify-center>
        <v-card-actions class="justify-center">
          <v-spacer></v-spacer>   
          <v-btn class="button-backorder-text" @click="dialogBackOrder = false">
            CANCELAR
          </v-btn>
         
          <v-btn
          v-if="doctype == 'PSIU'"
          class="button-backorder-text"
          :disabled="isButtonDisabled"
          @click="orderGenerate"
          >
          <span class="" style="font-size: 12px;" v-if="isButtonDisabled">Compra Bloqueada</span>
          <span  v-else class="">CONFIRMAR PEDIDO</span>
          <span ></span>
        </v-btn>
        </v-card-actions>
      </v-layout>
      </v-card>
    </v-dialog>
    <!-- Dialog loading -->
    <v-dialog v-model="dialogSpecilaOrder"       
      scrollable
      width="auto">
      <v-card round elevation="0">
        <v-btn class="mx-2 mt-2" fab x-small elevation="0" style="align-self: end;" @click="dialogSpecilaOrder=false">
          <v-icon dark>
            mdi-close
          </v-icon>
        </v-btn>
        <special-order-dialog-val :paramSelected="param_selected" :customerInfo="customerInfo" :cart="statecart" :statetotal="statetotal" :order_id="order_id" :idq="idq"></special-order-dialog-val>
      </v-card>
    </v-dialog>
    <!-- Dialog loading -->
    <v-dialog v-model="searchProductDialog" :persistent="searchProductsLoader" width="550" min-width="550">
      <v-card>
        <div class="w-full bg-black d-flex justify-content-between align-center px-4">
          <span class="text-white d-flex align-center">Productos</span>
          <v-btn v-if="!searchProductsLoader" icon small style="bg-gray" @click="searchProductDialog = false">
            <v-icon class="text-white font-size-8">mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
   <!-- Dialog loading -->
   <validate-customer-storage :order_id="$route.params.idb" :channel_order="user.VTWEG"/>
    </v-container>

      <!-- PRODUCT IMAGE GALLERY -->
      <CoolLightBox
        v-if="productGalleryImages"
        :items="productGalleryImages"
        :index="productGalleryImageIndex"
        @close="closeProductImageGallery()"
      />

    <!-- PRODUCT PDF FILES MODAL -->
      <product-technical-sheets v-model="isProductTechnicalShetsDialogOpen" :product="product"/>
  </div>
  </template>
  
  
  <script>
  import html2pdf from "html2pdf.js";
  import Papa from 'papaparse';
  import ShoppingCard from "./componets/ShoppingCard.vue";
  import LoadingBar from "./componets/LoadingBarOrder.vue"; 
  import ResultItemBackorder from "./componets/ResultItemBackorder.vue";
  import SpecialDiscountIcon from "./componets/SpecialDiscountIcon.vue";
  import DestinationSelector from './componets/DestinationSelector.vue';
  import fullScreenLoader from "@/mixins/fullScreenLoader";

  import CoolLightBox from 'vue-cool-lightbox'
  import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
  import ProductTechnicalSheets from "./componets/ProductTechnicalSheets.vue";
  import LegacyFooter from "@/views/seller/components/LegacyFooter.vue";

  import { mapActions,  mapGetters , mapMutations } from "vuex";
  import moment from 'moment'
  
  import axios from "@/plugins/axios";
  import ValidateCustomerStorage from "@/components/modals/ValidateCustomerStorage.vue"; 
  import SpecialGeneticsIcons from "./componets/SpecialGeneticsIcons.vue";
  import HistoryShoppingItem from "./componets/HistoryShoppingItem.vue";


  export default {
  name: "ShoppingCart",
  computed: {
    ...mapGetters({
      user: "auth/user",
      destinations: "ordercart/destinations",
      statecart: "ordercart/cart",
      statesubtotal: "ordercart/subtotal",
      stateiva: "ordercart/iva",
      statetotal: "ordercart/total",
      increase: "ordercart/increase",
      countItem: "ordercart/countItem",
      orderId: "ordercart/orderId",
      currentProduct: 'ordercart/currentProduct',
    }),
    isButtonDisabled(){
      if(this.doctype == 'PSIU'){
        if(this.statecart.length == 0 || this.submitted ){
          if(this.statecart.length == 0 ){
          return true;
        }else
          if(this.statecart.some((item) => item.recordatorios == 0)){ 
           if(this.$route.params.type == "PR"){
              return true;
            }else{
             return false;
           }
         }
        }
      }
        return false;
      },
      isDisabledTaxData(){
        if(this.customerInfo.ZTERM == "IU00"){
          console.log("SOY IU00");
          console.log(this.statecart.some((item) => item.recordatorios == 0));
          if((this.statecart.some((item) => item.recordatorios == 0)) == false){
            console.log("SITENGO RECORDATORIOS SOLOS");
            console.log(this.statecart.some((item) => item.recordatorios == 0));
            return false;
          }else{
            return true;
          }
          
        }
        return false;
       
      }
  },
  components: {
    HistoryShoppingItem,
    LegacyFooter,
    ValidateCustomerStorage,
    ShoppingCard,
    LoadingBar,
    //ResultItem,
    ResultItemBackorder,
    SpecialDiscountIcon,
    DestinationSelector,
    CoolLightBox,
    ProductTechnicalSheets,
    SpecialGeneticsIcons,
  },

  mixins: [fullScreenLoader],
  
  data: () => ({
    submitted: false,
    hasError: null,
    dialogLoading: false,
    destiny: "",
    order: "",
    cart: [],
    dialogBackOrder: false,
    order_response: "",
    invoice_response:"",
    delivery_response: "",
    purchase_response:"",
    order_id: null,
    showPrint:true,
    doctype: null,
    banderaProgramado:null,
    customerInfo: {
      NAME1: null,
      KUNNR: null,
    },
    searchForm: {
      valid: false,
      sku: null,
      quantity: 1,
    },
    quantityDialog: {
      show: false,
      id: null,
      name: null,
      quantity: null,
      item: null,
    },
    porcentaje: null,
    aviable: null,
    antiguedad:null,
    days_credit: null,
    dialogSpecilaOrder: false,
    param_selected:null,
    purchase_validation: 1,
    searchProductDialog: false,
    items:[],
    showNameField: false,
    selectedProducts: [],
    loading: false,
    docven: null,
    loadingCustomerinformation:true,
    searchProductsLoader:false,
    idq:null,
    customer_code:null,
    sellerOrderAviable: true,
    type: null,
    numero_vendedor:null,
    pathHome:null,
    customerInfo_1:[],
    item_metodo_pago: [],
    item_v_pay:[],
    item_use_cfdi:[],
    select_metodo:null,
    select_v_pay:null,
    select_use_cfdi:null,
    v_cfdi: null,
    documents : null,
    items_cfdi:[
    {value: '00', text:"0 - Sin Selección"},{value: '04', text: "04 - Refacturacion"}
    ],
    today2: moment().format('YYYY/MM/DD'), 
    schedule_orders:false,
    menu1: false,
    schedule_date: new Date( Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    totalCred:0,
    message_response: null,
    productGalleryImages: [],
    productGalleryImageIndex: null,
    isProductTechnicalShetsDialogOpen: false,
    product: null,
    typeBack:null,
    dataHistoryShopping:null,
  }),
  async mounted() {
    this.generarPDF();
  
    if (this.$hasAnyRole(['Customer','CustomerPR'])) {
    this.customer_code =  this.user.custumer_code;
    this.type = 'CL';
    this.numero_vendedor = this.user.custumer_code;
    this.pathHome = '/Home';
    
   }else{
    const currentCustomerInfo= JSON.parse(localStorage.getItem('currentCustomerInfo')) || [];
          if (currentCustomerInfo.length<1) {
          this.$router.push({name:'Home'})
          } 
    this.customerInfo_1 = currentCustomerInfo;
    this.customer_code =  parseInt(currentCustomerInfo?.KUNNR,10) 
    this.sellerOrderAviable = false;
    this.type = 'VE';
    this.numero_vendedor = this.user.employee_number;
    this.pathHome = '/caratula';
   } 
   this.generarPDF();
  
    try {
      this.doctype = this.$route.params.doctype;
      this.typeBack = this.$route.params.type;
      this.getInfoCustomer();
      this.getOrderID();

      await this.loadOrderBack({
        customer: this.customer_code,
        order_id: this.$route.params.idb,
        dd: 0,
      });

      await this.loadCartItems({
        custumer_code: this.customer_code,
        VKORG: this.user.VKORG,
        VTWEG: this.user.VTWEG,
        VKBUR: this.user.VKBUR,
        doctype: this.doctype,
      });

      this.getHistoryShopping();
      this.desactiveLoader(1000);

      if (this.customerInfo.DOCVENC > 0) {
       this.$toast.info("El pedido será bloqueado por crédito");
      }
    } catch (error) {
      this.desactiveLoader(1000);
    }
  },
  
  methods: {
    ...mapMutations({
      updateState: "ordercart/UPDATE_STATE",
      setCurrentProductIndex: 'ordercart/SET_CURRENT_PRODUCT_INDEX',
    }),
    ...mapActions({
      loadCustomerDestinations: "ordercart/loadCustomerDestinations",
      loadOrderBack: "ordercart/loadOrderBack",
      loadCartItems: "ordercart/loadCartItems",
      getMaterialInfo: "ordercart/getMaterialInfo",
      saveNewMaterialOrder: "ordercart/saveNewMaterialOrder",
      deleteMaterialOrder: "ordercart/deleteMaterialOrder",
      updateMaterialQuantity: "ordercart/updateMaterialQuantity",
      generateOrderBack: "ordercart/generateOrderBack",
      turnOrderIntoQuoter: "quotecart/turnOrderIntoQuoter",
      changeOrderType: "ordercart/changeOrderType",
      cleanMaterialOrder: "ordercart/cleanMaterialOrder",
      sendToExcel: 'printer/sendToExcel',
      sendToPDF: 'printer/sendToPDF',
      getProductImages: 'ordercart/getProductImages',
    }),
    ...mapActions('reminders', ['updateRemindersSelectionList', 'loadReminders']),
    generarPDF(){
    this.sendToPDF({active: true});
    },
    generarExcel(){
      let newItems = []
      this.itemCSV = []
  
      for (let key in this.statecart) {                
                let item = this.statecart[key];
                newItems.push({
                        'Producto': item.codigo_material ,
                        'Nombre': item.nombre_material,
                        'Genetica': item.material_type,
                        'Cantidad': item.u_confirm,
                        'Empaque': item.empaque,
                        'Unidad de Medida.':item.unidad_medida,
                        'Pedido Mensual.': item.PMCOM,
                        'Dias solicitados.': item.DSINV,
                        'Precio de lista': item.precio_lista,
                        'Precio C/ descuento':item.precio_con_descuento,
                        'Importe Total':item.importe_producto,
                    });
              }
      this.itemCSV = newItems
  
      let objectCSV = {
                'name': `Pedidos-${this.today2}`,
                'items': this.itemCSV,
                'showLabels': true
            }
  
    this.sendToExcel(objectCSV)
    },
    onEnterCode() {
        this.$refs["units"].focus();
    },
    getCustomerDestinations(customer_code){
      this.loadCustomerDestinations({
       custumer_code: customer_code,
       VKORG: this.user.VKORG,
       VTWEG: this.user.VTWEG,
       VKBUR: this.user.VKBUR,
    });
    },
    getInfoCustomer() {
      this.loadingCustomerinformation =true;
      axios
        .post( this.user.VTWEG == "PR" ? "info-customer-pr":"infoCustomer", {
         // customer: this.user.custumer_code,
          customer: this.customer_code,
          VKORG: this.user.VKORG,
          VTWEG: this.user.VTWEG,
          VKBUR: this.user.VKBUR,
        })
        .then((response) => {
          this.customerInfo = response.data.customer_data;
          this.item_metodo_pago = response.data.metodos_de_pago;
          this.item_v_pay = response.data.via_de_pago;
          this.item_use_cfdi = response.data.uso_cfdi;

          const taxkd = response.data.customer_data.TAXKD;
          this.$store.commit('ordercart/SET_CUSTOMER_IVA', taxkd);
  
          this.saldo = this.customerInfo.SKFOR.replace(/,/g, "");
          this.limite = this.customerInfo.KLIMK.replace(/,/g, "");
          this.porcentaje =
            (parseInt(this.saldo) * 100) / parseInt(this.limite);
          this.porcentaje = parseInt(this.porcentaje);
          this.aviable = this.customerInfo.CREDD.replace(/,/g, "");
          this.aviable = (this.aviable*1);
          
           
          this.getCustomerDestinations(this.customerInfo.KUNNR);
          this.totalCred = parseInt(this.aviable) - this.statecart;
  
          this.days_credit = parseInt(this.customerInfo.VTEXT.match(/\d+/g));
  
          this.validateOrderPurchase(this.customerInfo,this.doctype);
    
        })
        .catch((error) => {
          this.loadingCustomerinformation =false;
          console.log(error);
        })
        .finally(() => {
          this.loadingCustomerinformation =false;
        });
    },
    getOrderID() {

      axios
        .post("get-order-id-reminder-pr", {
            customer: this.customer_code,
            order_id: this.$route.params.idb,
        })
        .then((response) => {
          this.order_id = response.data.id;
          this.order = response.data.name;
          //this.doctype = this.doctype;
          //this.getCartItems(this.order_id);
          this.getOrderInfo(this.order_id);
        })
        .catch((error) => {
          ///alert(error);
          console.log(error);
          this.errormsg = true;
        })
        .finally();
    },
    getOrderInfo(id) {
      axios
        .post("get-order-info-pr", {
          id_order: id,
        })
        .then((response) => {
          this.order = response.data.name;
        })
        .catch((error) => {
         // alert(error);
         console.log(error);
         this.errormsg = true;
        })
        .finally();
    },
    getSKU(eventsku) {
      console.log(eventsku);
  
      // Obtener SKU
      const sku = eventsku.item.materialCode.slice(-6);
  
      // Obtener Name
      const name = eventsku.item.name; // Reemplaza 'name' con el nombre real del campo
  
      // Asignar valores a searchForm
      this.searchForm.sku = sku;
      this.searchForm.name = name;
      this.searchForm.url = eventsku.url
      this.showNameField = true;
  
      this.searchProductDialog = false;
    },
    clearSku() {
      this.searchForm.sku = "";
      this.showNameField = false;
  
    },
    async addItemToCart() {
     // if (this.orderType == null) {
       // this.$toast.warning("Debe seleccionar el Tipo de pedido");
       // return false;
      //}
      var ExpRegSoloNumeros="^[0-9]+$";
      //Evaluación de Cadena Invalida de Solo Números     
     if( this.searchForm.sku.match(ExpRegSoloNumeros)==null){
      axios.post("/materialComplete",{name:this.searchForm.sku}).then(response=>{
        console.log(response.data)
        this.searchProductDialog = true;
        this.items=response.data;
      })
     } else{ 
      let index = this.statecart.findIndex(
        (item) => item.codigo_material == this.searchForm.sku
      );
      console.log(index);
     }
      if (this.searchForm.sku == "" || this.searchForm.sku == null) {
        this.$toast.warning("El Código de material es requerido");
        return false;
      }
      if (this.searchForm.quantity <= 0) {
        this.searchForm.quantity =1
      }
      
      let index_back  = this.statecart.filter(
        (item) => item.codigo_material == this.searchForm.sku
        ).some((item) => item.backorder_id == null);

        if(index_back != false){
          this.$toast.warning("El código de material ya está en el carrito");
          return false;
        }

      try {
        this.loading = false;
        const product = await this.getMaterialInfo({
          sku: this.searchForm.sku,
          quantity: this.searchForm.quantity,
          //custumer_code: this.user.custumer_code,
          custumer_code: this.customer_code,
          VKORG: this.user.VKORG,
          VTWEG: this.user.VTWEG,
          VKBUR: this.user.VKBUR,
          order_type: this.doctype,
        });
        await this.saveNewMaterialOrder({
          order_id: this.$route.params.idb,
          item: product,
        });
        if(this.searchForm.quantity < product.empaque || product.u_confirm == 0){
        await this.updateMaterialQuantity({
          codigo_material: product.codigo_material,
          quantity: product.u_confirm == 0 ? product.recordatorios:product.u_confirm,
          custumer_code: this.customer_code,
          order_type: this.doctype,
         });

         this.$refs.searchMaterialForm.reset();
         this.$refs.searchMaterialForm.resetValidation();  
         this.loading = false;
         this.blockItemCart = false;
         this.$refs.input.focus();
         this.$toast.success("Producto agregado");
         
      }else{
        this.searchForm.sku = null;
        this.searchForm.quantity = null;
        this.showNameField = false;
        this.loading = false;
        this.$toast.success("Producto agregado");
        this.totalCred = parseInt(this.aviable) - this.statecart;
      }
      } catch (error) {
        if (error.response?.data?.error_code) {
          this.$toast.warning(error.response?.data?.error_code);
        } else {
         // this.$toast.warning("Ocurrio un error al agregar el producto");
        }
        console.log(error);
      }
    },
    resetIncrease() {
      this.updateIncreaseState(0);
    },
    editQuantity(item, cant) {
      let quantity = parseInt(cant);
      this.saveQuantity(item,quantity);
    },
    cancelQuantity() {
      this.quantityDialog.quantity = null;
      this.quantityDialog.name = null;
      this.quantityDialog.id = null;
      this.quantityDialog.item = null;
      this.quantityDialog.show = false;
    },
    saveQuantity(item,quantity) {
    this.updateMaterialQuantity({
      custumer_code: this.customer_code,
      codigo_material: item.codigo_material,
      quantity: quantity,
      precio_con_descuento:item.precio_con_descuento,
      cart_i: this.statecart,
      })
        .then((data) => {
          console.log(data);
          this.$toast.success("Producto actualizado");
          this.totalCred = parseInt(this.aviable) - this.statecart;
          this.cancelQuantity();
        })
        .catch((error) => {
          console.log(error);
          this.$toast.error(error);
        });
    },
    cleanOrder(){
      this.cleanMaterialOrder({
        order_id: this.order_id,
      })  .then((data) => {
          console.log(data);
          this.$toast.success("Se limpio el Pedido.");
          this.totalCred = parseInt(this.aviable) - this.statecart;
         // window.location.reload();
          
        })
        .catch((error) => {
          console.log(error);
          this.$toast.error(error);
        });
    },
    generateFiles() {
      //this.downloadPdf();
      this.exportToCSV();
    },
    formatInt(value) {
      try {
        return parseInt(value);
      } catch (error) {
        return value;
      }
    },
    formatPrice(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getItemTextOrderType(item) {
      return `(${item.type}) ${item.name}`;
    },
    format(value) {
      let result = Number(value).toFixed(2);
      return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    checkBackOrder() {
      this.hasError = null;
      if(this.isButtonDisabled == false){
          const existBackOrder = this.statecart.some((item) => item.recordatorios > 0);
         if (existBackOrder) {
            this.dialogBackOrder = true;
          } else {
           this.orderGenerate();
        }
      }
    },
    orderGenerate() {
     console.log("Doctype");
     console.log(this.order);
     console.log(this.purchase_validation);
    
     this.dialogBackOrder = false;
     this.dialogLoading = true;
     this.submitted = true;
     //alert(this.purchase_validation);
  
     if(this.customerInfo.DOCVENC  > 0 || this.aviable <= 1 || this.customerInfo.AUFSD == 1){
        this.purchase_validation = 2;
     }else{
       if(this.statetotal > this.aviable){
        this.purchase_validation = 2;
       }else{
        this.purchase_validation = 1;
       }
     }
  
      this.generateOrderBack({
        custumer_code: this.customer_code,
        orden_compra: this.order,
        carrito: this.statecart,
        orderType: this.doctype,
        destiny: parseInt(this.destiny.KUNNR),
        banderaProgramado: "",
        purchase_validation: this.purchase_validation,
        numero_vendedor: this.numero_vendedor,
        documents: this.documents,
        v_cfdi: this.v_cfdi,
        select_metodo: this.select_metodo,
        select_v_pay:  this.select_v_pay,
        select_use_cfdi:  this.select_use_cfdi,
        schedule_orders: this.schedule_orders,
        reminder_date: this.schedule_date,
  
      })
        .then((response) => {
         console.log(response);
         // console.log(response.data.RDCreaEntrega);
         if(response.message == "BACKORDER GENERADO" ){

          this.$swal({
           title: "Pedido Generado",
           text: response.message + 'Número de Pedido: S/N',
           icon: "success",
           type: "succes",
           confirmButtonText: "CONTINUAR",
           confirmButtonColor: "#A82222",
           closeOnConfirm: true,
           allowOutsideClick: false,
           allowEscapeKey: false,
           })

           this.$router.replace({
            name: "HistoryOrders",
              });
         
        
        }else{
          this.dialogLoading = false;
          this.order_response = response.VBELN;
          this.invoice_response = response.FACTURAS;
          this.delivery_response = response.ENTREGAS;
          this.purchase_response = response.PVALID == 1 ? '':'*Bloqueado por Credito';
          this.message_response = response.MYERR == 0 ? '':response.FNMSG;
          this.cart = [];
          this.order = null;

          const message = `Pedido: ${this.order_response}.
                         Entrega: ${this.delivery_response}.
                         Factura: ${this.invoice_response}.
                         ${this.purchase_response}.
                         ${this.message_response}.`;

  
           this.$swal({
           title: "Pedido Generado",
           text: message,
           icon: "success",
           type: "succes",
           confirmButtonText: "CONTINUAR",
           confirmButtonColor: "#A82222",
           closeOnConfirm: true,
           allowOutsideClick: false,
           allowEscapeKey: false,
           })

           this.$router.replace({
            name: "HistoryOrders",
              });
        }
        })
         .catch((error) => {
          console.log(error);
         this.dialogLoading = false;
          if(error.response.status == 404){
          //alert(error.response.data.error_code);
          if (error.response.data.message == "BACKORDER GENERADO") {
              this.$swal.fire(
                error.response.data.message,
                "Número de Pedido: S/N",
                "success"
              );
              this.$router.replace({
                name: "HistoryOrders",
              });
            }else{
              this.$swal({
              title: error.response.data.error_code,
              icon: "error",
              type: "error",
            }).then((result) => {
              console.log(result);
             // location.reload();
  
            });
            }
          }else{
            //alert(error);
            console.log(error);
          }
  
          this.submitted= false;
        })
        .finally(() => (this.dialogLoading = false, this.submitted= false));
      
    },
    goHistoryOrdes(){
      this.$router.replace({
            name: "HistoryOrders",
          });
    },
    goOrderCSV(){
      this.$router.replace({
            name: "upload-file",
      });
    },
    seePorcent(value) {
    return parseFloat(value * 100).toFixed(2);
   },
    deleteItem(item) {
      this.deleteMaterialOrder({ order_id: this.order_id, item }).then(() => {
        this.$toast.success("Producto elminado del carrito");
        this.totalCred = parseInt(this.aviable) - this.statecart;
      });
    },
    goSpecialOrder(param){
      console.log(param);
  
      if (param == 'PSIU'){
        axios
        .post("changeOrderType", {
          order_id: this.order_id,
          order_type: param,
        })
        .then((response) => {
            console.log(response);
           this.$router.push({ name: 'NewShoppingCartPR', params: { doctype: param } }).then(() => {
                this.$router.go(0)
                window.location.reload()
            }).catch((error) => {
                if (error.name != "NavigationDuplicated") {
                    throw error;
                }
            });
        }).catch((error) => {
          //alert(error);
          console.log(error);
          this.errormsg = true;
        }).finally();
      }else{
        this.dialogSpecilaOrder = true;
        this.param_selected = param;
      }
  
  
    },
    formatDestinyText(item) {
      return `${item.KUNNR} ${item.STRAS} ${item.ORT01} ${item.PSTLZ}`;
    },
    getItemTextMetodo(item) {
      return `(${item.id}) ${item.des}`;
    },
    getItemTextVPay(item) {
      return `(${item.id}) ${item.des}`;
    },
    getItemTextUseCfdi(item) {
      return `(${item.id}) ${item.des}`;
    },
    closeDateMenu1() {
        this.menu1 = false;
        this.schedule_date= new Date(
          this.schedule_date - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10);
          console.log(this.schedule_date);
      },
    async downloadPdf() {
      const valid = await this.validateBeforeSave();
      if (!valid) {
        return false;
      }
      this.loading = true;
      this.createPDF({
        customer_customer: this.customer_customer,
        customer_seller: this.customer_seller,
      })
        .then((pdf) => {
          const url = window.URL.createObjectURL(new Blob([pdf]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Pedidos.pdf"); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.log(error);
          this.$toast.warning("Ocurrio un error al generar el PDF");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    exportToCSV() {
   // const headers = ["Version", "Codigo de productos", "Orden de Compra"];
   // const dataLine2 = ["1.3.0", "IUSA", "OC-0"];
    const data = this.statecart.map((item) => {
      return [
      `${this.formatInt(item.u_confirm)}`,
        //`${this.formatInt(item.u_confirm)} ${item.recordatorios !== 0 ? ` BACKORDER ${item.recordatorios}` : ""}`,
        item.codigo_material,
        item.nombre_material,
      ];
    });
  
    const csvData = [["Version", "Codigo de productos", "Orden de Compra"],["1.3.0", "IUSA", "OC-0"],['Cantidad','Código', 'Nombre' ]];
    csvData.push(...data);
  
    // Convertir a CSV
    const csv = Papa.unparse(csvData);
  
    // Descargar el archivo CSV
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'Pedidos.csv';
    a.click();
   },
   /* showSearchProductDialog() {
      this.searchProductDialog = true;
    },*/
    handleCheckboxToggle(payload) {
      console.log("handleCheckboxToggle", payload);
      if (payload.checked) {
        this.selectedProducts.push(payload.item);
      } else {
        const index = this.selectedProducts.find(
          (item) => item.materialCode == payload.item.materialCode
        );
        this.selectedProducts.splice(index, 1);
      }
    },
    goTo() {
        this.$router.push(this.pathHome);
    },
    printPage(){  
      this.printing=true;
      this.showPrint = false;
     var opt = {
        margin: 0,
        filename: "Pedidos.pdf",
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 1.5 },
        jsPDF: { unit: 'px', format: [1100,1200], orientation: 'landscape' }
      };
      html2pdf(this.$refs.printResultSellerView,opt) 
        .then(pdf => {
          this.showPrint =true;
          this.printing=false;
          console.log(pdf);
        });  
    },
  
    createQuoter() {
      let type = "CL";
      this.turnOrderIntoQuoter({
       // custumer_code: this.user.custumer_code,
        custumer_code: this.customer_code,
        order_id: this.order_id,
        user_id: this.user.id,
        cart: this.statecart,
        type: type,
        doctype: this.doctype,
        channel: this.user.VTWEG,
        total: this.statetotal,
      })
        .then((data) => {
          console.log(data);
          this.$router.push({ name: 'QuoterHistory'});
  
        })
        .catch((error) => {
          console.log(error);
          this.$toast.error(error);
        });
    },
    validateOrderPurchase(customerInfo,doctype){
  
      console.log(doctype);
  
        if(customerInfo.AUFSD == 1){
         this.$toast.info("Estimado cliente usted tiene un bloqueo de partidas vencidas, Será bloqueado por crédito",
          {
            hideProgressBar: true,
            timeout: false,
          });
        }
    },
    async openProductImageGallery(product, index) {

      this.setCurrentProductIndex(index);

      if(!product.images) {

        this.productGalleryImages = [{
          title: 'Cargando...', 
          description: 'Cargando...', 
          src: product.image_url
        }];

        this.productGalleryImageIndex = 0;
        this.getProductImages(index).then((images) => {
          this.productGalleryImages = images;
        });
        
      } else {
        this.productGalleryImages = this.currentProduct.images;
        this.productGalleryImageIndex = 0;
      }
    },
    closeProductImageGallery() {
      this.productGalleryImageIndex = null;
      this.productGalleryImages = [];
    },
    async getHistoryShopping() {
     const params = {
     customerCode: this.customer_code
     };
     try{
      const response = await axios.post("/history-shopping-lite", params);
      this.dataHistoryShopping = response.data.renglonaje;
     }catch(error){
      this.error = "No el renglonaje";
     }
},
  },
    watch: {
    statecart: {
            immediate: true,
            handler() {
                this.generarExcel()
            },
        },  
    totalCred:{
      immediate: true,
      handler(){
        var aviable = this.customerInfo.CREDD.replace(/,/g, "");
        var aviableFormat = (aviable*1);
       //this.totalCred = parseFloat(aviableFormat) - parseFloat(this.statetotal);
       this.totalCred= parseFloat(aviableFormat)  - parseFloat(this.statetotal);
      }
   }  
    }, 
  };
  </script>
  
  <style scoped src="../../../public/css/shoppingpr.css"></style>
  <style>
  .v-text-field > .v-input__control > .v-input__slot:before {
    box-shadow: -1px 0px 50px 1px #bdbdbd;
    -webkit-box-shadow: -1px 0px 50px 1px #bdbdbd;
    -moz-box-shadow: -1px 0px 50px 1px #bdbdbd;
  }
  </style>
  
  
  
  