<template>
  <div>
    <v-badge color="info" :content="countItem" :value="countItem" overlap>
      <v-icon large color="black" height="5px" width="5px">
        mdi-cart-variant</v-icon
      >
    </v-badge>
    <div>Renglones este pedido</div>
    <hr />
    <div>
      <a class="text-black text-decoration-underline" @click="goToReng()">{{
        dataHistoryShopping
      }}</a>
      Renglones acumulados
      <div class="text-black">
        Trim {{ trim }} / {{ year }}
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on"
              ><v-icon color="red">mdi-information-outline</v-icon></span
            >
          </template>
          <span>Información al día anterior</span> </v-tooltip
        >
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";

export default {
  name: "HistoryShoppingItem",
  props: {
    countItem: {
      type: Number,
      required: true,
    },
    dataHistoryShopping: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    year: null,
    trim: null,
  }),
  async mounted() {
    this.year = moment().year();
    this.trim = moment().quarter();
  },
  methods: {
    goToReng() {
      const type = 1;
      const year = moment().year();
      const trim = moment().quarter();

      const paths = {
        1: "box-tied",
        2: "new-lines",
        3: "without-reorder",
        4: "special-orders-pnc",
        5: "families",
      };

      this.$router.push({
        name: "Renglonaje",
        params: {
          trim: trim,
          year: year,
          view: paths[type],
        },
      });
    },
  },
};
</script>
