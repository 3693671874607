<template>
  <div> 
  <v-card>
      <v-card-text class="pa-0 space-card">
              <div class="elevation-2 ">
                  <v-chip variant="flat" size="small" color="#FFFFFF" class="chip-style-title mb-1 mt-1">
                    <span class="text-chip-title">Para generar el pedido el cliente debe de tener:</span>
                   </v-chip>
                   <br>
                  <table style="border: none !important; border-bottom: none !important; width: 95%;">
                    <tbody>
                      <tr>
                        <td>
                          <v-chip variant="flat" size="x-small" color="#FFFFFF" class="chip-style mb-1 mt-1">
                            <span class="text-chip"> 6 meses de Antiguedad </span>
                          </v-chip>
                        </td>
                        <td>
                          <v-icon :color="antiguedad < 6  ? 'red' : 'green'">{{ antiguedad < 6  ? 'mdi-close-circle':'mdi-check-circle'}}</v-icon>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <v-chip variant="flat" size="x-small" color="#FFFFFF" class="chip-style mb-1 mt-1">
                            <span class="text-chip">Promedio Ponderado de 36 días </span>
                          </v-chip>
                        </td>
                        <td>
                          <v-icon :color="customerInfo.PPDP > 36 ? 'red' : 'green'">{{ customerInfo.PPDP > 36  ? 'mdi-close-circle':'mdi-check-circle'}}</v-icon>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <v-chip variant="flat" size="x-small" color="#FFFFFF" class="chip-style mb-1 mt-1">
                            <span class="text-chip">20 renglones en el último trimestre </span>
                          </v-chip>
                        </td>
                        <td>
                          <v-icon :color="customerInfo.RTRIMA < 20 ? 'red' : 'green'">{{ customerInfo.RTRIMA < 20  ? 'mdi-close-circle':'mdi-check-circle'}}</v-icon>
                        </td>
                      </tr>
                      <!--<tr>
                        <td>
                          <v-chip variant="flat" size="x-small" color="#FFFFFF" class="chip-style mb-1 mt-1">
                            <span class="text-chip">Puede hacer un pedido PNC al mes</span>
                          </v-chip>
                        </td>
                        <td>
                          <v-icon :color="customerInfo.PSNW > 0 ? 'red' : 'green'">{{ customerInfo.PSNW > 0  ? 'mdi-close-circle':'mdi-check-circle'}}</v-icon>
                        </td>
                      </tr>-->
                      <!-- <tr>
                        <td>
                          <v-chip variant="flat" size="x-small" color="#FFFFFF" class="chip-style mb-1 mt-1">
                            <span class="text-chip">Sin saldos / facturas vencidas </span>
                          </v-chip>
                        </td>
                        <td>
                          <v-icon :color="customerInfo.DOCVENC > 0 ? 'red' : 'green'">{{ customerInfo.DOCVENC > 0  ? 'mdi-close-circle':'mdi-check-circle'}}</v-icon>
                        </td>
                      </tr> -->
                    </tbody>
                  </table>
              </div>
      </v-card-text>
  </v-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from 'moment';
export default {
  name: "SpecialOrder",
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  props: ['customerInfo'],
  data: () => ({
    antiguedad:null,
  }),
  async mounted() {
  },
  watch: {
      'customerInfo.ERDAT':{
        immediate: true,
        handler(newDate) {
          const fechaActual = moment();
          this.antiguedad = fechaActual.diff(newDate, 'month')               
        }
      },      
    }, 
  
}
</script>
  
<style scoped> 
      .details--table{
          min-width: 400px;
          border: thin solid rgba(0,0,0,.12);
      }
      .details--table thead{
          background: #f2f2f2;        
      }
      .details--table thead th, .details--table tbody td{
          height: 35px !important;
          text-align: left;
      }
  
      .modal--head{
          background: #000;
          color: #fff;
          font-size: 16px;       
          padding-left: 5px;
          display: flex;
          justify-content: space-between;
      }    

      .space-card{
        background-color: white;
        margin-top: 2px;
        margin-left: 3px;
        margin-bottom: 2px;
        margin-right: -1px;
        overflow: hidden;
        align-content: left;
        box-shadow: -1px 0px 8px 1px pink;
      }

      .chip-style-title{
        margin-left: -20px;
        margin-right: 2px;
        text-align: center;
        width: 100%;
        box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
      }

      .chip-style{
        margin-left: -17px;
        margin-right: 2px;
        text-align: center;
        width: 100%;
        box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
      }

      .text-chip-title{
        margin-left: 15px;
        margin-right: 15px;
        font-weight: bold;
        color: black;
      }

      .text-chip{
        margin-left: 20px;
        margin-right: 0px;
        color: black;
        font-size: 13px;
        margin-top: 0px;
        margin-bottom: 0px;
      }

      
  </style>