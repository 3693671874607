<template>
  <div>
      <v-card elevation="0">
          <v-card-text>
              <div  v-if="paramSelected == 'PSPO'">
                  <v-icon  class="mt-20" :color="validateIconPSPO == false || materialVal  || cart.length == 0 ? 'red' : 'green'" size="128" style="align-self: center;">
                  {{ validateIconPSPO == false ||  materialVal || cart.length == 0 ? 'mdi-close-circle-outline':'mdi-check-circle-outline'}} 
              </v-icon>
              </div>
              <div  v-if="paramSelected == 'PSNW'">
                  <v-icon  class="mt-20" :color="validateIconPSNW == false || materialPop || statetotal < limitAmount || cart.length < 15 || cart.length == 0 ? 'red' : 'green'" size="128" style="align-self: center;">
                  {{ validateIconPSNW == false || materialPop || materialPNC || statetotal < limitAmount || cart.length < 15 ? 'mdi-close-circle-outline':'mdi-check-circle-outline'}} 
              </v-icon>
              </div>
              <p class="mt-5 text-dialog">RECUERDA QUE:</p>
              <p>Para generar el pedido
                  <span v-if="paramSelected == 'PSNW'">PRODUCTOS NUEVOS</span>
                  <span v-if="paramSelected == 'PSPO'">ARTÍCULOS PROMOCIONALES</span>
                  el cliente debe de tener:
              </p>
              <div v-for="(item, i) in validate_orders" :key="i">
                  <div v-if="paramSelected == 'PSNW'">
                      <div v-for="(item2, j) in item.psnw" :key="j">
                          <p>{{item2.name}}</p>
                      </div>

                      <p v-if="validateIconPSNW == false" class="font-weight-bold">AÚN NO COMPLETA:</p>
                      <div class="font-weight-bold" v-if=" cart.length < 15"> El carrito debe de tener minimo 15 renglones</div>
                      <div v-if="statetotal < limitAmount">
                            <span class="font-weight-bold">El monto mínimo de compra es $ {{limitAmount}}.00 MXP</span> 
                      </div>
                      <div  v-if="validateIconPSNW == false">
                          <div v-for="(item2, j) in item.psnw" :key="j">
                            <span class="font-weight-bold" v-if="antiguedad < 6 && item2.id == 1">{{item2.name}}</span>
                            <span class="font-weight-bold" v-if="customerInfo.PPDP > 36 && item2.id == 2">{{item2.name}}</span>
                            <span class="font-weight-bold" v-if="customerInfo.RTRIMA < 20 && item2.id == 3">{{item2.name}}</span>
                            <span class="font-weight-bold" v-if="customerInfo.PSNW > 0 && item2.id == 4">{{item2.name}}</span>
                            <span class="font-weight-bold" v-if="customerInfo.DOCVENC > 0 && item2.id == 5">{{item2.name}}</span>
                          </div>
                          <div v-if="materialPNC" class="mt-5">
                              <span  class="mt-10 mb-15 font-weight-bold">Estos Materiales NO Pertenecen a Pedido PRODUCTOS NUEVOS:</span>
                              <div v-for="(mat, k) in materialPrintPop" :key="k">
                                <span>Código: <span class="font-weight-bold">{{ mat.codigo_material }} - {{ mat.nombre_material }} </span></span>
                              </div>
                              <div v-for="(mat, k) in materialTipoPNC" :key="k">
                                <span>Código: <span class="font-weight-bold">{{ mat.codigo_material }} - {{ mat.nombre_material }} </span></span>
                              </div>
                              <span class="mt-10 text-note">Nota: Se eliminaran al cambiar de Pedido.</span>
                          </div>
                      </div>
                      <div v-if="cart.length == 0">
                         <span class="font-weight-bold">El carrito de materiales no puede ir vacio</span> 
                      </div>
                  </div>
                  <div v-if="paramSelected == 'PSPO'">
                      <div v-for="(item2, j) in item.pspo" :key="j">
                          <p>{{item2.name}}</p>
                      </div>
                      <p v-if="validateIconPSPO == false" class="font-weight-bold">AÚN NO COMPLETA:</p>
                          <div v-if="validateIconPSPO == false">
                              <div v-for="(item2, j) in item.psma" :key="j">
                                  <span class="font-weight-bold" v-if="customerInfo.PSMA > 0 && item2.id == 1">{{item2.name}}</span>
                              </div>
                          </div>
                          <div v-if="materialVal">
                              <span  class="mt-10 mb-15">Estos Materiales NO Pertenecen a Pedido ARTÍCULOS PROMOCIONALES: </span>
                              <div v-for="(mat, k) in materialPrint" :key="k">
                                <span>Código: <span class="font-weight-bold">{{ mat.codigo_material }} - {{ mat.nombre_material }}</span></span>
                              </div>
                          </div>
                          <div v-if="cart.length == 0">
                            <span class="font-weight-bold">El carrito de materiales no puede ir vacio</span> 
                          </div>
                  </div>
              </div>
          </v-card-text>
          <v-layout justify-center>
          <v-card-actions class="justify-center">
              <div  v-if="paramSelected == 'PSPO'">
                  <v-btn :disabled="cart.length == 0 
                          || validateIconPSPO == false
                          || materialVal" class="button-dialog-text text-white font-weight-bold"
                          @click="gotoOrder(order_id,paramSelected,idq)">
                      CONTINUAR
                  </v-btn>
              </div>
              <div  v-if="paramSelected == 'PSNW'">
                  <v-btn :disabled="
                          cart.length == 0 
                          || cart.length < 15
                          || statetotal < limitAmount
                          || validateIconPSNW == false
                          || materialPop" class="button-dialog-text text-white font-weight-bold"
                           @click="gotoOrder(order_id,paramSelected,idq)">
                      CONTINUAR
                  </v-btn>
              </div>                
          </v-card-actions>
      </v-layout>
      </v-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from 'moment';
import axios from "@/plugins/axios";
import { MIN_ORDER_AMOUNT, NPO_MIN_CUSTOMER_TENURE_MONTHS, NPO_MIN_INVOICED_LINES, MIN_ORDER_AMOUNT_DT} from "@/core/constants/orderConstants";
export default {
name: "SpecialOrderDilaog",
computed: {
  ...mapGetters({
    user: "auth/user",
  }),
},
props: ['paramSelected','customerInfo','cart','statetotal','order_id','idq'],
data: () => ({
  antiguedad:null,
  validateIconPSPO:true,
  validateIconPSNW:true,
  textData: null,
  materialVal:null,
  materialPop:null,
  materialPrint:null,
  materialPrintPop:null,
  materialPNC:null,
  materialTipoPNC: null,
  limitAmount: 0,
  validate_orders:[
      {
          psnw: [
              { id: 1, name: `${NPO_MIN_CUSTOMER_TENURE_MONTHS} meses de Antiguedad` },
              { id: 2, name: 'Promedio Ponderado de 36 días' },
              { id: 3, name: `${NPO_MIN_INVOICED_LINES} renglones en el último trimestre` },
             // { id: 4, name: 'Puede hacer un pedido PNC al mes' },
              // { id: 5, name: 'Sin saldos / facturas vencidas'},
              ],
          pspo: [
              { id: 1, name: '' },
            ],
      }
  ]
}),
async mounted() {

  this.limitAmount = this.paramSelected != 'PSNW' && this.user.VTWEG == 'DT' ? MIN_ORDER_AMOUNT_DT: MIN_ORDER_AMOUNT;

  if (this.antiguedad < NPO_MIN_CUSTOMER_TENURE_MONTHS || this.customerInfo.PPDP > 36 || this.customerInfo.RTRIMA < NPO_MIN_INVOICED_LINES) {
      this.validateIconPSNW = false;
  }

},
methods: {
  gotoOrder(order_id,param,idq){

      axios
      .post("changeOrderType", {
        order_id: order_id,
        order_type: param,
      })
      .then((response) => {
          console.log(response);
         this.$router.push({ name: 'NewShoppingCart', params: { doctype: param, idq: idq } }).then(() => {
              this.$router.go(0)
              window.location.reload()
          }).catch((error) => {
              if (error.name != "NavigationDuplicated") {
                  throw error;
              }
          });
      });
  },
},
watch: {
    'customerInfo.ERDAT':{
      immediate: true,
      handler(newDate) {
        const fechaActual = moment();
        this.antiguedad = fechaActual.diff(newDate, 'month')               
      }
    },
    'cart':{
      immediate: true,
      handler(materialAdd) {
          /*Validcacion para tipo de materiales que no se encuantran dentro de los grupos (MVGR4) para tipo de pedido POP Y PSMA */
          this.materialVal = materialAdd.some((item) => item.MVGR4 !== '1ZA' && item.MVGR4 !== '1ZB' && item.MVGR4 !== '1ZC' && item.MVGR4 !=='1YA');
          this.materialPrint = materialAdd.filter((item)=> item.MVGR4 !== '1ZA' && item.MVGR4 !== '1ZB' && item.MVGR4 !== '1ZC' && item.MVGR4 !=='1YA');
          
          /*Validacion para materiales que son tipo pop, tipo de pedido (PSNW, PSDR, PSNT)*/
          this.materialPop = materialAdd.some((item) => item.TIPOP == '1');
          this.materialPrintPop = materialAdd.filter((item)=> item.TIPOP == '1');
          /*Validar si no hay material tipo PNC  de Productos no comprados*/
          this.materialPNC = materialAdd.some((item) => item.PNC != 0 || item.sector == 30 || item.sector == 90);
          this.materialTipoPNC = materialAdd.filter((item)=> item.PNC != 0 || item.sector == 30 || item.sector == 90);

          

      }

    }
    
  },      

}
</script>
<style scoped> 
.text-dialog {
  align-self: center; 
  font-size: 30px;
  color: black; 
  font-weight: bolder;
}
.button-dialog-text{
  align-self: center !important; 
  border: 3px solid #DC3545;
  border-radius: 7px;
  background: #C8233375 !important;
  color: white;
}
.text-note{
  align-self: center !important; 
  font-size: 12px;
}
</style>