<template>
  <div v-if="item.recordatorios != 0" class="w-full d-flex justify-space-between align-center rounded shadow-in m-1 p-1">
      <v-checkbox class="mx-2" v-model="checked">
          <template v-slot:label>
          </template>
      </v-checkbox>
      <div style="width: 70px" class="mr-1">
          <img class="w-full" :src="src" alt="" />
      </div>
  
      <div class="flex-grow-1 mx-2">
        <div class="text-left">
          <span class="text-color-bk">{{ item.name }}</span>
          </div>
          <div class="text-left mt-0 ">
              <span class="text-color-bk">SKU {{ item.sku }}</span>
          </div>
          <div class="text-left mt-0 d-flex justify-space-between align-center">
              <span class="font-weight-bold text-color-bk-c">Backorder {{ item.recordatorios }}</span>
              <span class="font-weight-bold text-bk-price">${{ item.precio_lista }}</span>
          </div>
      </div>
      <div class="d-flex justify-center align-center" style="height: 20px">
      </div>
  </div>
</template>
  
<script>
  import { mapActions } from "vuex";
  //import generalMixin from "@/mixins/general";
  export default {
    name: "ResultItem",
    props: ["item"],
    //mixins: [generalMixin],
    data: () => ({
      src: null,
      checked: false,
    }),
    mounted() {
      this.checked = this.item.reminder;
      this.loadImage();
    },
    methods: {
      ...mapActions({
        getImageUrl: "quotecart/getImageUrl",
      }),
      loadImage() {
        if (!this.item || this.item.materialCode == null) {
          return;
        }
        this.getImageUrl({ codigo_material: this.item.materialCode })
          .then((imagePath) => {
            this.src = imagePath;  
          })
          .catch((error) => {
            console.log(error);
          });
      },
    },
  };
  </script>
  
<style scoped>
  .shadow-in {
    box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
  }
  .text-color-bk{
    color: gray;
  }

  .text-color-bk-c{
    color: black;
    font-size: 12px;
  }

  .text-bk-price{
    color: #B00606;
    font-size: 16px;
  }
  </style>
  