<template>
  <div id="app">
    <v-app>
      <v-main>  
        <LoaderIusa></LoaderIusa>  
        <NavBar />
        <BreadcrumbRoles v-if="!$hasAnyRole(['CustomerPR', 'Customer'])"/>
        <CustomerMenu />
        <v-alert type="info" :value="alert" class="my-2">
          {{ message }}
        </v-alert>

        <!-- Key force view update when going to a same route but diferent parameters ex: /user/123 -> user/689 -->
        <router-view :key="$route.path"/> 
        <RightNavDrawer/>       
      </v-main>
      
      <reload-without-cache-btn v-if="authenticated"/>
      <modal-steps-after-login />
      <Footer />
    </v-app>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import Pusher from "pusher-js";

import { eventBus } from "@/main.js";
import generalMixin from "./mixins/general";
import userInactivityTime from "./mixins/userInactivityTime";
import NavBar from "@/template/partials/NavBar.vue";
import CustomerMenu from "@/template/partials/customerMenu/CustomerMenu.vue";
import BreadcrumbRoles from "./template/partials/life_line/BreadcrumbRoles.vue";
// import PRNavBar from "@/template/partials/PRNavBar.vue";
//import SideBar from "@/template/partials/SideBar.vue";
import RightNavDrawer from "@/template/partials/RightNavDrawer.vue";
import Footer from "@/template/partials/Footer.vue"; 
import LoaderIusa from '@/components/LoaderIusa.vue'
import ModalStepsAfterLogin from '@/template/partials/stepsAfterLogin/ModalStepsAfterLogin.vue'
import ReloadWithoutCacheBtn from "./components/ReloadWithoutCacheBtn.vue";

export default {
  components: {
    NavBar,
    // PRNavBar,
    //SideBar,
    CustomerMenu,
    Footer,
    RightNavDrawer,
    BreadcrumbRoles,
    LoaderIusa,
    ModalStepsAfterLogin,
    ReloadWithoutCacheBtn
  },

  mixins: [generalMixin, userInactivityTime],  

  created () {
    this.launchModalSteps();
  },

  computed: {
    ...mapGetters({
      token: "auth/token",
      message: "auth/message",
      alert: "auth/alert",
      user: "auth/user",
      authenticated: "auth/authenticated",
      userCode: "auth/userCode",
      hasSteps: "modalStepsAfterLogin/hasSteps",
      showModal: "modalStepsAfterLogin/showModal"
    }),
  },

  methods: {
    ...mapActions({
      signOutAction: "auth/signOut",
      signInVel: "auth/sigInVel",
      loadModalSteps: 'modalStepsAfterLogin/loadModalSteps',
      updateModalState: 'modalStepsAfterLogin/updateModalState'
    }),
    ...mapMutations({
      setVisibleAlert: "auth/SET_VISIBLE_ALERT",
      addNewNotification: "auth/ADD_NEW_NOTIFICATION",
      addDiary: "diary/ADD_DIARY",
      setDrawerState: "diary/SET_DRAWER_STATE",
    }),
    subscribe() {
      if(this.user && this.user.id) {
        var pusher = new Pusher(this.$pusherAppKey, { 
          cluster: 'us2',
          authEndpoint: this.$baseApiUrl + '/pusher/auth'
        });
        console.log('User ID', this.user.id);
        pusher.subscribe('private-users-' + this.user.id);
  
        pusher.bind('Illuminate\\Notifications\\Events\\BroadcastNotificationCreated', data => {
          this.playSound();
          console.log(data);

          if(data.type == 'App\\Notifications\\CustomerRequestAccepted') {

            if(data.event_type == 'accepted') {
              this.$swal('Tu registro ha sido aceptado. Se te redigirá en 3 segundos.', { type: 'loading' });
              setTimeout(() => {
                  if(this.$route.name != 'Home') {
                      this.$router.push('/home').then(() => { location.reload(); });
                } else {
                    location.reload();
                }
              }, 5000);
            } else {
              this.addNewNotification(data);
            }

          } else {
            this.notify('Nuevo evento registrado');
            let event = { notifiable_type: 'App\\Models\\User', notifiable_id: this.user.id, data: data };
            eventBus.$emit('openDrawer', event);
          }
        });
      }
    },    

    async launchModalSteps (authenticated) {
      await this.loadModalSteps();
      
      if(this.hasSteps && authenticated) this.updateModalState(true);
      else this.updateModalState(false)
    }
  },

  watch: {
    authenticated(newValue) {
      this.launchModalSteps(newValue);
    },

    showModal(newValue) {
      console.log('watch', newValue, this.authenticated)
      if(newValue && !this.authenticated) this.updateModalState(false);
    }
  },

  beforeDestroy() {
    document.removeEventListener('mousemove', this.resetInactivityTimer);
    document.removeEventListener('keydown', this.resetInactivityTimer);

    clearTimeout(this.inactivityTimeout);
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: rgb(255,255,255);
  background: radial-gradient(circle, rgba(255,255,255,1) 85%, rgba(139,139,139,1) 100%);
}
html, body {
    max-width: 100% !important;
    overflow-x: hidden !important;
}
.d-image {
  width: 100%;
}
</style>
